import Carousel from 'react-bootstrap/Carousel';
import CarrouselData from '../../Json/Carrousel.json';

import PRUEBA from '../../Image/CarrouselBG.png';
import IMG1 from '../../Image/1024px-Digicel_logo.svg.svg';
import IMG2 from '../../Image/Mask-Group-64.svg';
import IMG3 from '../../Image/Mask-Group-65.svg';
import IMG4 from '../../Image/Mask-Group-66.svg';
import IMG5 from '../../Image/Wind-logo-Duotono.svg';

const CardCarrousel = () => {
    return(
        <Carousel fade className="class-first-carousel" id="Home">
        {CarrouselData.carrousel.map((props, i) => (
            <Carousel.Item key={i}>
              <img className="d-block w-100" src={PRUEBA} alt={props.alt}/>
              <Carousel.Caption>
                  <div>
                    <h2>{props.TitleCardCarrousel}</h2>
                    <p>{props.InfoCardCarrousel}</p>
                    <div>
                        <img className="" src={IMG1} alt={props.alt}/>
                        <img className="" src={IMG2} alt={props.alt}/>
                        <img className="" src={IMG3} alt={props.alt}/>
                        <img className="" src={IMG4} alt={props.alt}/>
                        <img className="" src={IMG5} alt={props.alt}/>
                    </div>
                  </div>
              </Carousel.Caption>
            </Carousel.Item>
        ))}
        </Carousel>
    )
    
}

export default CardCarrousel;