import ComponentAccordion from './accordion';
import ComponentCard from './cards/card';

import IMG1 from '../Image/touchscreen-hand.svg';
import IMG2 from '../Image/monitor-mouse-graph-coin.svg';
import IMG3 from '../Image/hands-keyboard-chat-bubbles.svg';
import IMG4 from '../Image/preview(3).svg';

const CardAboutUs = [
    {
        "src": IMG1,
        "Title": "Simple",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    },
    {
        "src": IMG2,
        "Title": "Mas Beneficios",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    },
    {
        "src": IMG3,
        "Title": "Recursos",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    },
    {
        "src": IMG4,
        "Title": "Seguridad",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    }
]

const AboutUs = (props:any) => {

    return(
        <div className="class-AboutUs">
            <div id="SobreNosotros"></div>
            <ComponentAccordion />
            <div>
            {CardAboutUs.map((props, i) => (
                <ComponentCard 
                    key={i}
                    src={props.src}
                    title={props.Title}
                    info={props.Info}
                />
            ))}
            </div>
        </div>
    )
}

export default AboutUs;