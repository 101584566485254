import ComponentCard from './cards/card';

import IMG1 from '../Image/touchscreen-hand.svg';

const CardServices = [
    {
        "src": IMG1,
        "Title": "Simple",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    },
    {
        "src": IMG1,
        "Title": "Mas Beneficios",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    },
    {
        "src": IMG1,
        "Title": "Recursos",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    },
    {
        "src": IMG1,
        "Title": "Seguridad",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    }
]

const Services = (props:any) => {
    return(
        <div className="class-Services">
            <div></div>
            <div>
                <h2 id="Servicios">Servicios principales</h2>
                {CardServices.map((props, i) => (
                    <ComponentCard 
                        key={i}
                        src={props.src}
                        title={props.Title}
                        info={props.Info}
                    />
                ))}
            </div>
        </div>
    )
}

export default Services;