import Form from 'react-bootstrap/Form';
import {Button} from 'react-bootstrap';
import emailjs from 'emailjs-com';
import {Fragment} from "react";

const Contacts = (props:any) => {

    function sendEmail(e:any) {
        e.preventDefault();
    
        emailjs.sendForm('service_greencard', 'template_thdkt97', e.target, 'user_R00U7G8Q3075JHonYE7al')
          .then((result) => {
              alert("Mensaje Enviado con exito!");
          }, (error) => {
              alert("Ha ocurrido un error, intentelo nuevamente");
          });
      }

    return(
        <Fragment>
        <div className="class-Contacts">
            <Form className="contact_form" onSubmit={sendEmail}>
                <h2 id="Contacto">Contacto</h2>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label htmlFor="name">Nombre
                        <Form.Control type="text" placeholder="Nombre" id="name" name="Nombre" />
                    </Form.Label>

                    <Form.Label htmlFor="email">Correo
                        <Form.Control type="email" placeholder="Correo" id="email" name="Correo" />
                    </Form.Label>

                    <Form.Label htmlFor="phone">Teléfono
                        <Form.Control type="number" placeholder="Teléfono" id="phone" name="Telefono" />
                    </Form.Label>

                    <Form.Label htmlFor="company">Compañia
                        <Form.Control type="text" placeholder="Tu Compañia" id="company" name="Compania" />
                    </Form.Label>
                </Form.Group>

                <Form.Group className="form-group2" controlId="formBasicEmail2">
                    <Form.Label htmlFor="message">Mensaje
                        <Form.Control as="textarea" rows={3} 
                        placeholder="Mensaje" id="message" name="Mensaje" style={{resize:"none"}}/>
                    </Form.Label>

                    <Button variant="success" type="submit">ENVIAR</Button>
                </Form.Group>
            </Form>
        </div>
        <style>{`
            // .contact_form {
            //     background:white;
            //     padding:5px 10px;
            //     box-sizing:border-box;
            //     border-radius:10px;
            // }

            // .contact_form .form-group{
            //     background-color:white !important;
            //     box-shadow:none;
            // }
        `}
        </style>
        </Fragment>
    )
}

export default Contacts;