import Accordion from 'react-bootstrap/Accordion';
import {Card , Button} from 'react-bootstrap'
import AccordionData from '../Json/AccordionData.json';

const ComponentAccordion = (props:any) => {
    return(
        <Accordion defaultActiveKey="0">
            {AccordionData.accordionData.map((props, i) => (
                <Card key={i}>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={props.eventKey}>
                            {props.Title}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={props.eventKey}>
                        <Card.Body>
                            {props.Info}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        </Accordion>
    )
}

export default ComponentAccordion;