import React from 'react';

import Menu from './Components/menu';
import AboutUs from './Components/AboutUs';
import Services from './Components/Services';
import ClientsOpinion from './Components/ClientsOpinion';
import CardCarrousel from './Components/cards/cardCarrousel';
import MapComponent from './Components/MapComponent';
import Contacts from './Components/Contacts';
import Footer from './Components/footer';

import LOGO_IMG from './Image/Logo_GREEN_CARD.svg';
import iconNosotros from './Image/Page-1.svg';
import iconProductos from './Image/Page-2.svg';
import iconInicio from './Image/preview(5).svg';
import iconContactos from './Image/preview(8).svg';

import ICON_Instagram from './Image/social-instagram.svg';
import ICON_Twitter from './Image/social-twitter.svg';
import ICON_Facebook from './Image/social-facebook.svg';
import ICON_Visa from './Image/visa.svg';
import ICON_Mastercard from './Image/mastercard.svg';
import ICON_Paypal from './Image/paypal.svg';

import './css/style.scss';
import './css/responsive.scss';
import './css/animate.scss';

import '../../Image/touchscreen-hand.svg';

import './js/scrollQuery'


const App = (props:any) => {
  
  return(
    <>
      <Menu
        src={LOGO_IMG}
        iconNosotros={iconNosotros}
        iconProductos={iconProductos}
        iconInicio={iconInicio}
        iconContactos={iconContactos}
      />
      <div>
        <CardCarrousel/>
        <AboutUs/>
        <Services/>
        <ClientsOpinion/>
        <MapComponent />
        <Contacts />
        <Footer
          src={LOGO_IMG}
          ICON_Instagram={ICON_Instagram}
          ICON_Twitter={ICON_Twitter}
          ICON_Facebook={ICON_Facebook}
          ICON_Visa={ICON_Visa}
          ICON_Mastercard={ICON_Mastercard}
          ICON_Paypal={ICON_Paypal}
        />
      </div>
    </>
  )
}

export default App;
