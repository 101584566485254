import { Nav } from 'react-bootstrap';

const Footer = (props:any) => {
    return(
        <div className="class-Footer">
            <div>
                <img className="d-block w-50" src={props.src} alt="#"/>
            </div>
            <div>
                <p>
                   <b>Calle:</b>&nbsp;&nbsp; no. 00,
                    Santo Domingo,
                    República Dominicana
                </p>
                <p>
                    <b>Teléfono:</b> &nbsp;&nbsp;
                    +1 (800) 444 444 1111</p>
                <p>
                    <b>Correo:</b> &nbsp;&nbsp;
                    hello@betstickofficial.com
                </p>
            </div>
            <div>
                <Nav>
                    <Nav.Link href="#Home">
                        Inicio
                    </Nav.Link>
                    <Nav.Link href="#SobreNosotros">
                        Nosotros
                    </Nav.Link>
                    <Nav.Link href="#Servicios">
                        Servicios
                    </Nav.Link>
                    <Nav.Link href="#Contacto">
                        Contactos
                    </Nav.Link>
                </Nav>
            </div>
            <div>
                <h6>Siguenos</h6>
                <img src={props.ICON_Instagram} alt={props.ICON_Instagram}/>
                <img src={props.ICON_Twitter} alt={props.ICON_Twitter}/>
                <img src={props.ICON_Facebook} alt={props.ICON_Facebook}/>
                <h6>Metodos de pago</h6>
                <img src={props.ICON_Visa} alt={props.ICON_Visa}/>
                <img src={props.ICON_Mastercard} alt={props.ICON_Mastercard}/>
                <img src={props.ICON_Paypal} alt={props.ICON_Paypal}/>
            </div>
            <div>
                {/* <hr/> */}
                <p>
                    {new Date().getFullYear()} GREEN CARD  All rights reserved | Privacy Policy 
                </p>
            </div>
            <div>
                {/* <hr/> */}
                <p>
                    All rights reserved 
                </p>
            </div>
        </div>
    )
}

export default Footer;