import Carousel from 'react-bootstrap/Carousel';

import IMG1 from '../Image/userImg1.jpg';
import IMG2 from '../Image/userImg2.jpg';

const CardAboutUs = [
    {
        "src": IMG1,
        "numberStars": "star5",
        "Title": "Simple",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    },
    {
        "src": IMG2,
        "numberStars":"star4",
        "Title": "Mas Beneficios",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    },
    {
        "src": IMG1,
        "numberStars":"star3",
        "Title": "Recursos",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    },
    {
        "src": IMG2,
        "numberStars":"star2",
        "Title": "Seguridad",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    }
    ,
    {
        "src": IMG1,
        "numberStars":"star1",
        "Title": "Seguridad",
        "Info": "La plataforma más fácil de República Dominicana; Somos una empresa sólida y dinámica que tenemos como prioridad principa."
    }
]

const ClientsOpinion = (props:any) => {
    return(
        <div className="class-ClientsOpinion">
            <div></div>
            <h2>Clientes satisfechos:</h2>
            <Carousel fade>
                {CardAboutUs.map((props, i) => (
                    <Carousel.Item key={i}>
                    <Carousel.Caption>
                        <div>
                            <div>
                                <img className="" src={props.src} alt={props.src}/>
                            </div>
                            <div>
                                <h5>{props.Title}</h5>
                                <div className={props.numberStars}>
                                    <div/>
                                    <div/>
                                    <div/>
                                    <div/>
                                    <div/>
                                </div>
                                <p>{props.Info}</p>
                            </div>
                        </div>
                    </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}

export default ClientsOpinion;