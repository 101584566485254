const MapComponent = (props:any) => {
    return(
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15138.902311913047!2d-69.91930024999999!3d18.4507649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2sdo!4v1616515128392!5m2!1ses!2sdo" 
            width="100%" 
            height="500" 
            loading="lazy"
            title="map-green-card"
        ></iframe>
    )
}

export default MapComponent;