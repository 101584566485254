import Card from 'react-bootstrap/Card';

const ComponentCard = (props:any) => {
    return(
        <Card className="class-cardElement">
            <Card.Img src={props.src} resource={props.src} />
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>
                {props.info}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default ComponentCard;