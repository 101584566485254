import React, { useState, useEffect } from 'react';

import useMediaQuery from "../js/jsMediaQuery";

import {
    Navbar , 
    Nav , 
    Form , 
    FormControl , 
    Button,
    Tooltip
} from 'react-bootstrap';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const Menu = (props:any) => {
    const [menuResponsive, setMenuResponsive] = useState(false);

    let isPageWide = useMediaQuery("(min-width: 1025px)");

    let [iconSize, setIconSize] = useState("");

    useEffect(() =>{
        setIconSize("22px");
    }, []);

    const activeMenu = () => {
        setMenuResponsive(true)
    }

    const desactiveMenu = () => {
        setMenuResponsive(false)
    }

    const goToWebSite = () => {
        const url = "http://app.greencard.do/";
        window.open(url, '_blank');
    }

    return(
        <>
        { (isPageWide || menuResponsive) ? ( 
        <Navbar expand="lg">
            <Navbar.Brand href="#home">
                <img src={props.src} alt={props.src} style={{display:"block", margin:"0 auto"}} onClick={desactiveMenu}/>
            </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="#Home">
                            <img src={props.iconInicio} alt={props.iconInicio} style={{height: iconSize}}/> Inicio
                    </Nav.Link>
                    <Nav.Link href="#SobreNosotros">
                        <img src={props.iconNosotros} alt={props.iconNosotros} style={{height: iconSize}}/> Nosotros
                    </Nav.Link>
                    <Nav.Link href="#Servicios">
                        <img src={props.iconProductos} alt={props.iconProductos} style={{height: iconSize}}/> Productos
                    </Nav.Link>
                    <Nav.Link href="#Contacto">
                        <img src={props.iconContactos} alt={props.iconContactos} style={{height: iconSize}}/> Contactos
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
            
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Pronto tendremos el servicio en linea</Tooltip>}>
                <Form inline>
                    {/* <h5 style={{color:"white"}}>Iniciar Sección</h5>
                    <FormControl type="text" placeholder="Usuario" className="" disabled/>
                    <FormControl type="password" placeholder="Contraseña" className="" disabled/>
                    <Button variant="" disabled>Iniciar Sección</Button>
                    <Button variant="" disabled>Entrar con google</Button> */}
                    <Button className="btn btn-success" 
                        style={{border:"1px solid white"}} 
                        onClick={goToWebSite}>Ir al Login</Button>
                </Form>
            </OverlayTrigger>
        </Navbar>
        )
        :
        (
        <div className="class-menuDisable">
            <img src={props.src} alt={props.src} onClick={activeMenu}/>
        </div>
        )
        }
        </>
    )
}

export default Menu;